/* 基本スタイル */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

/* ヘッダー */
header {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

/* メインビジュアル */
.main-visual {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("./KeyVisual.png"); /* 画像のパスはsrcフォルダ内の画像に合わせて設定 */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: fixed;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
}

/* グラデーションオーバーレイ */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.7)
  );
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

/* テキスト */
.text-content {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  padding-top: 3%;
}

.text-content h1 {
  font-size: 2.5rem;
  margin: 0;
  margin-bottom: 10px;
}

.text-content p {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}

/* 下線 */
.divider {
  width: 80%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5); /* 透明度50%の白い下線 */
  margin: 20px auto; /* 上下の間隔を確保 */
}

/* ボタンリンク */
.button-links {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}

.button {
  padding: 12px 24px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background-color 0.3s, border-color 0.3s, text-decoration 0.3s;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 1);
  text-decoration: underline;
}

/* 右下アイコンボタンのスタイル */
.social-icons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
}

.social-icons .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  color: white;
  font-size: 1.5rem;
  transition: background-color 0.3s, transform 0.3s;
  text-decoration: none;
}

.social-icons .icon:hover {
  background-color: rgba(255, 255, 255, 0.5);
  transform: scale(1.1);
}

/* 個別のアイコンカラー */
.icon.twitter:hover {
  color: #1da1f2;
}

.icon.mixcloud:hover {
  color: #27323a;
}

/* VRChatアイコンのスタイル */
.icon.vrchat .icon-image {
  width: 24px;
  height: 24px;
}

.icon.vrchat:hover .icon-image {
  opacity: 0.8; /* ホバー時のスタイル */
}

/* ボタンスタイル */
.button {
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.button.active {
  background-color: #007bff;
  color: #fff;
}

/* Podcastを表示するエリア */
.podcast-container,
.artworks-container,
.blog-container {
  position: absolute;
  margin-top: 20px; /* ボタンとの余白 */
  top: 250px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-height: 60%;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明の背景 */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: white; /* テキストを見やすくするため白に設定 */
  animation: fadeIn 0.5s ease-in-out forwards;
  overflow-y: auto;
}

/* フェードインアニメーション */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
  opacity: 1;
}

/* Podcast一覧のスタイル */
.podcast-list {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  color: #333;
}
.podcast-item {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.podcast-header h3,
.podcast-header h4 {
  margin: 0;
  font-size: 1.2rem;
  color: #444;
}

.podcast-header audio {
  margin-top: 10px;
  width: 90%;
  max-width: 300px;
}

.toggle-button {
  font-size: 1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #45a049;
}

.podcast-details {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  text-align: left;
}

.podcast-image {
  height: 200px;
  width: auto;
  margin-right: 10px;
  border-radius: 8px;
}

.additional-text {
  font-size: 0.9rem;
  color: #333;
  margin-top: 0;
}

.artwork-item img {
  max-width: 600px;
  max-height: 600px;
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
}
